import React from 'react';
import { graphql } from 'gatsby';

import About from '../../components/pages/about';

export default (props) => <About {...props} />;

export const aboutQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about/index.md" }) {
      frontmatter {
        templateKey
      }
      html
    }
  }
`;
